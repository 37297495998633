var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Sponsored video")]),
      _vm.videoCode
        ? [
            !_vm.isOurVideo
              ? _c("YTPlayer", {
                  staticClass: "fixed inset-0 w-full h-full",
                  attrs: { "video-code": _vm.videoCode, autoplay: "" },
                  on: { end: _vm.navigateToGame },
                })
              : _vm.videoCode
              ? _c("VideoComponent", {
                  ref: "player",
                  staticClass:
                    "absolute top-0 left-0 w-full h-full object-contain --reset-flip",
                  attrs: { src: _vm.videoCode, autoplay: "", controls: "" },
                  on: { ended: _vm.navigateToGame },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "fixed",
          staticStyle: { right: "8px", bottom: "80px" },
          attrs: { color: "red", dark: "" },
          on: { click: _vm.navigateToGame },
        },
        [_vm._v("Skip")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }