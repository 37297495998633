<template>
  <section>
    <h1 class="sr-only">Sponsored video</h1>
    <template v-if="videoCode">
      <YTPlayer
        :video-code="videoCode"
        autoplay
        class="fixed inset-0 w-full h-full"
        @end="navigateToGame"
        v-if="!isOurVideo"
      />
      <VideoComponent
        v-else-if="videoCode"
        ref="player"
        :src="videoCode"
        autoplay
        controls
        @ended="navigateToGame"
        class="absolute top-0 left-0 w-full h-full object-contain --reset-flip"
      />
    </template>
    <v-btn
      color="red"
      dark
      class="fixed"
      style="right: 8px; bottom: 80px"
      @click="navigateToGame"
      >Skip</v-btn
    >
  </section>
</template>

<script>
import Vue from "vue"
import isVideo from "is-video"

import { GetterTypes } from "@/store"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

export default Vue.extend({
  name: "GameLatecomer",
  components: {
    VideoComponent,
    YTPlayer: () => import("@/components/VideoPlayers/YTPlayer.vue")
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    /** @returns {(string | undefined)} */
    videoCode() {
      return this.$store.getters[GetterTypes.LATECOMER_VIDEO].code
    },
    isOurVideo() {
      return isVideo(this.videoCode)
    }
  },
  created() {
    if (!this.videoCode) {
      this.navigateToGame()
    }
  },
  methods: {
    navigateToGame() {
      this.$navigator.navigateTo(`/game/${this.id}`)
    }
  }
})
</script>
